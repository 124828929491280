import {toggleLoaderStatusAC} from "../reducers/MainReducer";
import toast from 'react-hot-toast';

function dispatcherCatch(error, dispatch){
    dispatch(toggleLoaderStatusAC(false));
    let msg = error.hasOwnProperty('error') ? error.error : error.message;
    toast.error(msg)
    // dispatch(setAlertMessageThunk(msg, "error"));
}
export const dispatcherErrorThunk  = (error)=> async dispatch =>{
    dispatcherCatch(error, dispatch)
}



