import React, {useEffect} from 'react';
import ButtonPrint from "./ButtonPrint";
import useClickOutside from "../../../hook/useClickOutside";
import useKeydownPress from "../../../hook/useKeydownPress";
import {gaEvents} from "../../../gaEvents";

const OrderDetailPrintGl = ({getT, order, getPdfHandler, onClicklLabels}) => {
        const {isVisible, setVisible, ref} = useClickOutside();
    const {isKeyPress, setKeyPress} = useKeydownPress({keyCode: 71})
    useEffect(()=>{
        // if(Boolean(localStorage.getItem('_crm_vis_gl')) && isKeyPress){
        //     return false;
        // }
        // if(Boolean(localStorage.getItem('_crm_vis_gl')) && !isKeyPress){
        //     setKeyPress(localStorage.getItem('_crm_vis_gl'));
        // }
        // if(!Boolean(localStorage.getItem('_crm_vis_gl')) && isKeyPress){
        //     localStorage.setItem('_crm_vis_gl', isKeyPress);
        // }

    }, [isKeyPress]);
    if(!isKeyPress) return <></>
    return (
        <div className={'position-relative order-detail-print-gl'} ref={ref}>
            <ButtonPrint getT={getT} onClick={()=> setVisible(!isVisible)}  typeActionChildren='' isFileIcon={true} titleChildren={<>GL</>}/>
            {isVisible ? <div className="print_all_documents_container open">
                {/*open modal */}


                {!!order.project_data.calculate_vals  ? <>
                 <div className="item" onClick={()=>{
                     gaEvents.customEvent('klik_form', {
                         cutting_card: 'Карты раскроя',
                     });
                     getPdfHandler("cards", order.project_data)}}>{getT('Карты раскроя')}</div>
                    <div className="item" onClick={()=>{
                        gaEvents.customEvent('klik_form', {
                            label_gl: 'Этикетки GL',
                        })
                        onClicklLabels()
                    }}>{getT('Этикетки GL')}</div>
               <div className="item" onClick={()=>{
                   gaEvents.customEvent('klik_form', {
                       full_report_pdf: 'Полный отчет',
                   })
                   getPdfHandler("pdf", order.project_data)}}> {getT("Полный отчет")}</div>
                </> : ''}
                {Number(order.xnc_operations) === 1 ?   <div className="item" onClick={()=>{
                    gaEvents.customEvent('klik_form', {
                        full_report_pdf_xnc: 'Сводный отчет ЧПУ',
                    })
                    getPdfHandler("xnc", order.project_data)}}> {getT("Сводный отчет")} {getT("ЧПУ")}</div> : ""}

            </div> : ''}
        </div>
    );
};

export default OrderDetailPrintGl;