import { useEffect, useState } from "react";


export default function({keyCode}) {
    const [isKeyPress, setKeyPress] = useState(false);
    useEffect(()=>{
        const onPressKey = (event)=>{
            // event.preventDefault();
            // if(isKeyPress) return false;
            if((event.keyCode === 17  || event.shiftKey)&& (event.keyCode === Number(keyCode)) && (event.ctrlKey || event.keyCode === 16)){
                setKeyPress(prev=> !prev);
            }
        }
        window.addEventListener('keydown', onPressKey)
        return () => window.removeEventListener('keydown', onPressKey)
    }, []);

    return {isKeyPress, setKeyPress}

}