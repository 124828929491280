import React from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import OrderDetail from "./OrderDetail";
import "./OrderDetail.css";
import {
    changeCalcTypeIntoAC,
    changeOrderDetailValue,
    changePrintDocumentsDataAC,
    fetchingMergeHtmlFiles,
    fetchingUpdateCommentOfDetail,
    getCuttingLabelsForPrint,
    getGiblabDataThunk,
    getOrdersFilteredDataThunk,
    getPDFLoaderSetterThunk,
    setFileManagerDT,
    setOrderDetailDataThunk,
    setOrderDetailItemDiscount,
    toggleOrderChangeStatusAC,
    toggleOrderDetailEditThunk,
    toggleOrdersDiscountEditThunk,
    updateOrderDetailDataThunk
} from "../../store/reducers/MainReducer";
import {Redirect} from "react-router-dom";
import Loader from "../Loader/Loader";

const PROTECTED_COMPONENT_NO_ACCEESS_STATUS = [1]

class OrdersDetailContainer extends React.Component {
    constructor(props) {
        super(props);
        this.id = this.props.uri.match.params.id;
        this.printDocumentsPackage = this.printDocumentsPackage.bind(this);
    }

    componentDidMount() {

        this.props.getOrder(this.id);
    }

    componentWillUnmount() {
        // this.props.checkAuntificationUser()
    }

    printDocumentsPackage(order) {
        let print_array = [];
        this.props.printDocumentsData.order_blank_short &&
        print_array.push("order_blank_short");
        this.props.printDocumentsData.order_blank_short_without &&
        print_array.push("order_blank_short_without");
        this.props.printDocumentsData.cards && print_array.push("cards");
        this.props.printDocumentsData.xnc && print_array.push("xnc");
        this.props.printDocumentsData.material_list &&
        print_array.push("material_list");
        order.print_array = print_array;
        this.props.getPDF("merge", order);
    }

    render() {
        return this.props.order && this.props.order.confirm ?
            <React.Fragment>
                {PROTECTED_COMPONENT_NO_ACCEESS_STATUS.includes(Number(this.props.order.status)) ?
                   <RedirectToActualPage orderId={this.id}/>
                    :
                    <OrderDetail
                        order={this.props.order}
                        getPDF={this.props.getPDF}
                        discountEdit={this.props.discountEdit}
                        editDiscount={this.props.editDiscount}
                        discounts={this.props.discounts}
                        setDiscount={this.props.setDiscount}
                        orderID={this.id}
                        isEdit={this.props.isEdit}
                        editOrder={this.props.editOrder}
                        changePrintDocumentsData={this.props.changePrintDocumentsData}
                        printDocumentsData={this.props.printDocumentsData}
                        printDocumentsPackage={this.printDocumentsPackage}
                        changeValue={this.props.changeValue}
                        changeCalcType={this.props.changeCalcType}
                        changedStatus={this.props.changedStatus}
                        updateOrder={this.props.updateOrder}
                        status={this.props.orderStatus}
                        userId={this?.props?.user?.id}
                        userRoles={this.props.user.role}
                        toggleChangeStatus={this.props.toggleChangeStatus}
                        onSetFileManager={this.props.onSetFileManager}
                        onUpdateComment={this.props.onUpdateComment}
                        getLabelsPrintHtml={this.props.getLabelsPrintHtml}
                        getGiblabDataThunk={this.props.getGiblabDataThunk}
                        fetchingMergeHtml={this.props.fetchingMergeHtml}
                    />}
            </React.Fragment>

            : this.props.order?.message_error ? <p>{this.props.order?.message_error}</p> :
               <Loader/>

    }
}

let mapDispatchToProps = (dispatch) => ({
    getOrder: (id) => dispatch(setOrderDetailDataThunk(id)),
    getPDF: (action, order) => dispatch(getPDFLoaderSetterThunk(action, order)),
    discountEdit: (
        status,
        calculate,
        currency,
        order,
        discounts,
        version,
        total,
        orderID
    ) =>
        dispatch(
            toggleOrdersDiscountEditThunk(
                status,
                calculate,
                currency,
                order,
                discounts,
                version,
                total,
                orderID
            )
        ),
    setDiscount: (id, type, price) =>
        dispatch(setOrderDetailItemDiscount(id, type, price)),
    editOrder: (status, order_id, data) =>
        dispatch(toggleOrderDetailEditThunk(status, order_id, data)),
    changeValue: (input, value) => dispatch(changeOrderDetailValue(input, value)),
    changePrintDocumentsData: (input, value) =>
        dispatch(changePrintDocumentsDataAC(input, value)),
    changeCalcType: (calc_type, id) =>
        dispatch(changeCalcTypeIntoAC(calc_type, id)),
    updateOrder: (order_id, data, redirectTo = false) =>
        dispatch(updateOrderDetailDataThunk(order_id, data, redirectTo)),
    toggleChangeStatus: (id) => dispatch(toggleOrderChangeStatusAC(id)),
    onSetFileManager: (payload) => dispatch(setFileManagerDT(payload)),
    onUpdateComment: ({orderId, comment, cb}) => dispatch(fetchingUpdateCommentOfDetail({orderId, comment, cb})),
    getLabelsPrintHtml: (action) => dispatch(getCuttingLabelsForPrint(action)),
    getGiblabDataThunk: (type, order) => dispatch(getGiblabDataThunk(type, order)),
    fetchingMergeHtml: (actions, order) => dispatch(fetchingMergeHtmlFiles({actions, order}))
});

let mapStateToProps = (state) => {
    return {
        order: state.main.order_detail,
        editDiscount: state.main.orderDiscountsEdit,
        discounts: state.main.orderDiscounts,
        isEdit: state.main.orderDetailEdit,
        orderStatus: state.main.orderStatus,
        changedStatus: state.main.ordersChangeStatus,
        user: state.auth.user,
        printDocumentsData: state.main.printDocuments,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrdersDetailContainer);


const RedirectToActualPage = (props) => {
    let {orderId} = props;
    const dispatch = useDispatch();
    const filter = useSelector(state => state.main.ordersForm);
    dispatch(getOrdersFilteredDataThunk("id", orderId, filter));
    return <Redirect to={'/orders/actual'}/>

}