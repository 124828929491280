import React, {useState} from "react";
import "../../plugins/flag-icon-css/css/flag-icon.min.css";
import "./Header.css";
import ApiService from "../../api/api";
import {useDetectClickOutside} from "react-detect-click-outside/dist";
import {languagesList} from "../../store/reducers/TranslationReducer";
import Globe from "../../Icons/Globe";
import {withLang} from "../../hoc/withLang";
import {LS_USER_ID} from "../../utils/constants";

const Header = (props) => {

    const api = new ApiService();
    const [isSelect, setIsSelect] = useState(false);
    const refSelect = useDetectClickOutside({onTriggered: () => setIsSelect(false)});
    const is_user_authorized = !!Number(localStorage.getItem(LS_USER_ID));
    return (
        <div className="wrapper">
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-widget="pushmenu"
                            href="#"
                            role="button"
                            onClick={() => props.toggleSidebar(!props.sidebarCollapse)}
                        >
                            <i className="fas fa-bars"></i>
                        </a>
                    </li>

                </ul>
                {is_user_authorized &&  <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link"
                            onClick={() => {
                                if (props.notify && props.notify.length > 0) {
                                    props.toggleNotify(!props.notifyOpen);
                                }
                            }}
                        >
                            <i className="far fa-comments"></i>
                            {props.notify && props.notify.length > 0 && (
                                <span className="badge badge-danger navbar-badge">
                  {props.notify ? props.notify.length : ""}{" "}
                </span>
                            )}
                        </a>
                        <div
                            className={"dropdown-menu" + (props.notifyOpen ? " show" : "")}
                        >
                            {props.notify &&
                            props.notify.length > 0 &&
                            props.notify.map((e) => {
                                return (
                                    <>
                                        <div
                                            className="dropdown-item"
                                            onClick={() => props.updateNotify(e.id, e.link)}
                                        >
                                            <div className="media ">
                                                <i className="fas fa-cart-arrow-down"></i>
                                                <div className="media-body">
                                                    <h3 className="dropdown-item-title">
                                                        {e.title}
                                                        <span className="float-right text-sm text-danger">
                                <i className="fas fa-star"></i>
                              </span>
                                                    </h3>
                                                    <p className="text-sm">{e.message}</p>
                                                    <p className="text-sm text-muted">
                                                        <i className="far fa-clock mr-1"></i> {e.date}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                    </>
                                );
                            })}
                        </div>
                    </li>
                </ul>}

                <ul className="navbar-nav ml-auto lang-selector">
                    {(Number(localStorage.getItem('i_furn_user_id')) === 2044 || Number(localStorage.getItem('i_furn_user_id')) === 88 )&&
                    <button type={"button"} onClick={props.onSendCollectionWords}>send words</button>
                    }
                    {/*<li className={'nav-item dropdown' + (props.lang.isOpen ? ' show' : '')} >*/}
                    {/*<a className="nav-link" onClick={() => props.toggleLangMenu(!props.lang.isOpen)}>*/}
                    {/*<i className={'flag-icon flag-icon-' + props.lang.active}></i>*/}
                    {/*</a>*/}
                    {/*<div className={'dropdown-menu dropdown-menu-right p-0' + (props.lang.isOpen ? ' show' : '')}>*/}
                    {/*{props.lang.languages.map(e => {*/}
                    {/*return <a key={e.code} href="#" className={'dropdown-item' + (props.lang.active === e.code ? ' active' : '')}>*/}
                    {/*<i className={'flag-icon flag-icon-' + e.code + ' mr-2'}></i> {e.name}*/}
                    {/*</a>*/}
                    {/*})}*/}
                    {/*</div>*/}
                    {/*</li>*/}
                    <div className="select-custom" ref={refSelect}>
                        <div className="select__head"
                             onClick={() => setIsSelect(!isSelect)}>
                            <Globe/>
                            {props.localLang}
                        </div>
                        {isSelect && <ul className="select__body">
                            {languagesList.map((code, index) => {
                                return <li
                                    onClick={(e) => props.onChangeLang(code)}
                                    key={index} value={code}
                                    className={props.localLang === code ? 'disabled' : ''}
                                >
                                    {code}
                                </li>
                            })}
                        </ul>}
                    </div>
                    <li className="nav-item">
            <span
                className={"text-danger"}
                onClick={() => {
                    api.logoutCabinet().then(res => {
                        if (res && res.result) {
                            localStorage.removeItem("i_furn_user_id");
                            localStorage.removeItem("i_furn_user_firm");
                            window.location.href = "/login";
                        }
                    })

                }}
            >
                {props.getT("Выход")} <i className="fas fa-sign-out-alt"></i>
            </span>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default withLang(Header);
