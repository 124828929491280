import ApiService from "../api";
import axios from "axios";
import {LS_LANG} from "../../utils/constants";

const api_service = new ApiService();
const _apiKey = api_service._apiKey
const _uri_api = api_service._baseUrl;

export const URI_API_EXTTUNELL = '/exttunnel';

export let instances = axios.create({
    baseURL: _uri_api,
    withCredentials: true,
    headers: {
        "X-Api-Key": _apiKey,
        'Language': localStorage.getItem(LS_LANG) || 'ua',

    }
});
instances.interceptors.request.use(
    async (config) => {
        config.headers['Language'] = localStorage.getItem(LS_LANG) || 'ua';
        return config;
    },
    error => {
        Promise.reject(
            error.response.data,
        )
    }
)
// Response interceptor for API calls
instances.interceptors.response.use((response) => {
    return response
}, async function (err) {
    // сохраняем данные запроса которые завершились 401 - для повтороного запроса после успешного обновления токена
    const originalConfig = err.config;
    // console.log('originalConfig', originalConfig._isRetry)
    // console.log(err.config);
    if ((err.response.status === 403) && !originalConfig._isRetry) {
        // предохранитель который запрещает зацыкленный вызов рефреш токена
        // указываем что запрос мы уже делали
        originalConfig._isRetry = true;
        console.log('Пользователь не авторизован e', err);
        api_service.logoutCabinet().then(res => {
            if (res && res.result) {
                localStorage.removeItem("i_furn_user_id");
                localStorage.removeItem("i_furn_user_firm");
                window.location.href = "/login";
            }
        })

        return Promise.reject(err);
    }


    // if (err.response.status === AuthStatusCode.Forbidden && err.response.data) {
    //     return Promise.reject(err.response)
    // }
    return Promise.reject(
        {...err.response.data, status: err.response.status},
    );


});
