import {applyMiddleware, combineReducers, createStore} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import AuthReducer from "./reducers/AuthReducer";
import MainReducer from './reducers/MainReducer';
import {TranslationReducer} from "./reducers/TranslationReducer";
import {ArchiveProjectReducer} from "./reducers/ArchiveProjectReducer";
import SalaryProjectReducer from "./reducers/SalaryProjectReducer";
import {helpArticleReducer} from "./reducers/HelpArticleReducer";
import {StatisticReducer} from "./reducers/StatisticReducer";

let reducers = combineReducers({
    main: MainReducer,
    auth: AuthReducer,
    translation: TranslationReducer,
    archive: ArchiveProjectReducer,
    salary_project: SalaryProjectReducer,
    help_article: helpArticleReducer,
    statistic: StatisticReducer
});

let store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

window.store = store;

export default store;