import React from 'react';

const IconFile = ({stroke='#c2c7d0'}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6z" stroke={stroke} strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M14 2v6h6M16 13H8M16 17H8M10 9H8" stroke={stroke} strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"></path>
        </svg>

    );
};

export default IconFile;