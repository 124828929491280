import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const GENERAL_CURRENCY = 'general_currency';
const GENERAL_GOODS_SERVICE = 'general_goods_service';
const GENERAL_GOODS_SERVICE_TYPE = 'general_goods_service_type';

/**
 * Базовые справочники
 * @method currencies  -    валюты
 */
export const GeneralAPI = {
    currencies() {
        return instances.post(URI_API_EXTTUNELL, null, {
            headers: {
                action: GENERAL_CURRENCY
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    goods_service() {
        return instances.post(URI_API_EXTTUNELL, null, {
            headers: {
                action: GENERAL_GOODS_SERVICE
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    goods_service_type() {
        return instances.post(URI_API_EXTTUNELL, null, {
            headers: {
                action: GENERAL_GOODS_SERVICE_TYPE
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },

}