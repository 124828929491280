import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {dispatcherErrorThunk} from "../common_thunk";
import {GeneralAPI} from "../../../api/Salary/GeneralAPI";
import {
    setGeneralCurrenciesAC,
    setGeneralGoodsServiceAC,
    setGeneralGoodsServiceTypeAC
} from "../../actions/SalaryProject";

export const getGeneralCurrenciesThunk = () => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await GeneralAPI.currencies();
        if(response.error){
           dispatch(dispatcherErrorThunk(response));
           return
        }
        dispatch(setGeneralCurrenciesAC(response))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
export const getGeneralGoodsServiceThunk = () => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await GeneralAPI.goods_service();
        if(response.error){
           dispatch(dispatcherErrorThunk(response));
           return
        }
        dispatch(setGeneralGoodsServiceAC(response))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

export const getGeneralGoodsServiceTypeThunk = () => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await GeneralAPI.goods_service_type();
        if(response.error){
            dispatch(dispatcherErrorThunk(response));
            return
        }
        dispatch(setGeneralGoodsServiceTypeAC(response))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}