import React from 'react';
import {FULL_FIELD_NAME_BY_KEY} from "../../../../constans/statistics_xnc_operations_constant";

// const FIELD_NAME_BY_KEY = {
//     average_depth_mm: {
//         title: 'Средняя толщина детали',
//         unit: null,
//         is_has_action_modal_filter_data_by_role: false,
//     },
//     details_on_sheet_average: {
//         title: 'Количество деталей на листе',
//         unit: null,
//         is_has_action_modal_filter_data_by_role: false,
//     },
{/*    orders_per_day_average: {*/}
{/*        title: 'Количество заказов в день',*/}
{/*        unit: null,*/}
//         is_has_action_modal_filter_data_by_role: false,
//     },
//     square_of_one_detail: {
//         title: 'Средняя площадь детали',
//         unit: null,
//         is_has_action_modal_filter_data_by_role: false,
//     },
//     count_detail: {
//         title: 'Количество деталей',
//         unit: null,
//         is_has_action_modal_filter_data_by_role: true
//     },
//     lines_count: {
//         title: 'Количество строк в заказах',
//         unit: null,
//         is_has_action_modal_filter_data_by_role: true
//     },
//     orders_count: {
//         title: 'Количество заказов',
//         unit: null,
//         is_has_action_modal_filter_data_by_role: true
//     },
//     perimetr: {
//         title: 'Периметр всех деталей в заказах',
//         unit: 'м',
//         is_has_action_modal_filter_data_by_role: true
//     },
//     square: {
//         title: 'Площадь всех деталей в заказах',
//         unit: ' м2',
//         is_has_action_modal_filter_data_by_role: true
//     },
//     volume: {
//         title: 'Объём всех заказов',
//         unit: ' м3',
//         is_has_action_modal_filter_data_by_role: true
//     },
// }
const StatisticsFull = ({data, getT, handlerOpenModalSeeMoreStaticsByKey=null}) => {
    return (
        <>
            {Object.keys(FULL_FIELD_NAME_BY_KEY)
               ?.map(key => {
                   let value = data?.[key] ? `${data?.[key]} ${getT(FULL_FIELD_NAME_BY_KEY?.[key]?.unit ?? '')}` : '-'
                    let is_display_modal_icon = FULL_FIELD_NAME_BY_KEY[key]?.is_has_action_modal_filter_data_by_role && handlerOpenModalSeeMoreStaticsByKey;
                    return <li className="list-group-item d-flex align-items-center justify-content-between px-1 py-2">
                        <span className={"title"}> {getT(FULL_FIELD_NAME_BY_KEY[key].title)}:</span>
                        <span className={"font-weight-bold"}>{value}
                            {!!(is_display_modal_icon) &&  <i className="far fa-eye text-primary ml-2 cursor-pointer" onClick={handlerOpenModalSeeMoreStaticsByKey(key)}> </i>}
                        </span>
                    </li>
                })}
        </>

    );
};

export default StatisticsFull;